import React from "react";

function Logo(props) {
  return (
    <img
      alt="Logo"
      src={"/logo/logo_color.png"}
      width={124}
      height={38.5}
      {...props}
      style={{ background: "transparent", filter: "brightness(0) invert(0)" }}
    />
  );
}

export default Logo;
