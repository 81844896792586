import React from "react";
import { Typography, Box, Grid, useTheme } from "@mui/material";
import Container from "../Container";
import { GradientIconLight } from "../GradientIcon";
import QuickreplyRoundedIcon from "@mui/icons-material/QuickreplyRounded";
import SchemaRoundedIcon from "@mui/icons-material/SchemaRounded";
import RateReviewRoundedIcon from "@mui/icons-material/RateReviewRounded";
import AssessmentRoundedIcon from "@mui/icons-material/AssessmentRounded";
import IntegrationInstructionsIcon from "@mui/icons-material/IntegrationInstructions";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import BugReportIcon from "@mui/icons-material/BugReport";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import CrisisAlertIcon from "@mui/icons-material/CrisisAlert";

function Usecases({ usecases }) {
  const theme = useTheme();

  return (
    <Container>
      <Typography width={"100%"} textAlign={"center"} sx={{ typography: { md: "h2", xs: "h3" } }}>
        Use Cases
      </Typography>
      <Box mb={{ md: 5, xs: 4 }} />
      <Grid container spacing={3}>
        {usecases.map((usecase) => {
          const { key, title, description, image } = usecase;
          return (
            <Grid item xs={12} sm={6} md={4} key={key}>
              <Box
                height={300}
                display="flex"
                flexDirection="column"
                borderRadius={{ xs: 3, md: 4 }}
                px={3}
                py={2}
                sx={{
                  background: theme.palette.background.light,
                }}
              >
                <Box
                  sx={{
                    mr: 2,
                    background:
                      "linear-gradient(140deg, rgba(215, 213, 213, 0.08) 14.9%, rgba(255, 255, 255, 0.08) 38.71%, rgba(33, 36, 38, 0.08) 71.41%)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: 48,
                    height: 48,
                    borderRadius: "50%",
                  }}
                >
                  <GradientIconLight Icon={image} sx={{ fontSize: 33 }} />
                </Box>
                <Box mb={2} />
                <Box height={70} overflow="hidden">
                  <Typography
                    variant="h5"
                    sx={{
                      display: "-webkit-box",
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      lineHeight: 1.2,
                      maxHeight: "2.4em",
                    }}
                  >
                    {title}
                  </Typography>
                </Box>
                <Box mb={1} />
                <Box flex={1} overflow="hidden">
                  <Typography
                    variant="subtitle2"
                    color="textSecondary"
                    sx={{
                      display: "-webkit-box",
                      WebkitLineClamp: 6,
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {description}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
}

export default Usecases;
