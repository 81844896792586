import { useMediaQuery, useTheme } from "@mui/material";
import React from "react";

function GradientIcon({ Icon }) {
  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
      <svg width={0} height={0}>
        <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
          <stop stop-color="#EF8FE2" />
          <stop offset="0.5" stop-color="#B08FEF" />
          <stop offset="1" stop-color="#9A8FEF" />
        </linearGradient>
      </svg>
      <Icon
        fontSize={isXS ? "medium" : "large"}
        sx={{ fill: "url(#linearColors)" }}
      />
    </>
  );
}

export default GradientIcon;

export function GradientIconLight({ Icon, sx }) {
  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
      <svg width={0} height={0}>
        <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
          <stop stop-color="#BF40AE" />
          <stop offset="0.5" stop-color="#6B40BF" />
          <stop offset="1" stop-color="#4E40BF" />
        </linearGradient>
      </svg>
      <Icon
        fontSize={isXS ? "medium" : "large"}
        sx={{ fill: "url(#linearColors)", ...sx }}
      />
    </>
  );
}
