import React from "react";
import Container from "../Container";
import { Typography, Box, IconButton, useMediaQuery } from "@mui/material";
import {
  BoltRounded,
  DashboardRounded,
  PrecisionManufacturingRounded,
  TaskRounded,
} from "@mui/icons-material";
import TrendingUpRoundedIcon from "@mui/icons-material/TrendingUpRounded";
import SchoolRoundedIcon from "@mui/icons-material/SchoolRounded";
import { useTheme } from "@mui/material/styles";
import { GradientIconLight } from "../GradientIcon";

const data = [
  {
    title: "10-15% OEE Improvement",
    icon: PrecisionManufacturingRounded,
  },
  { title: "2%-10% Asset Uptime Improvement", icon: TrendingUpRoundedIcon },
  { title: "40% Faster Onboarding & Training", icon: SchoolRoundedIcon },
];

function Benefits({ title, subtitle }) {
  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Container>
      <Typography
        sx={{
          typography: { xs: "h5", md: "h4" },
          textAlign: "center",
          maxWidth: "800px",
          margin: "0 auto",
        }}
        component={"h4"}
        mt={8}
        mb={6}
      >
        {title}
      </Typography>
      <Box mb={4} />
      <Typography
        sx={{
          typography: { xs: "body1", md: "subtitle1" },
          color: "text.secondary",
          textAlign: "left",
          textAlign: "justify",
          maxWidth: "800px",
          margin: "0 auto",
          whiteSpace: "pre-line",
        }}
        mb={8}
        dangerouslySetInnerHTML={{ __html: subtitle }}
      ></Typography>
      <Box mb={{ xs: 6, md: 15 }} />
      <Box
        gap={2.5}
        display={"flex"}
        flexDirection={{ xs: "column", md: "row" }}
        alignItems={{ xs: "start", md: "center" }}
        justifyContent={"space-between"}
      >
        {/* <Box
          position="relative"
          display="inline-flex"
          alignItems="center"
          justifyContent="center">
          <img
            src="/icons/halo_shape.svg"
            alt="Halo shape"
            style={{
              position: "absolute",
              width: "150%",
              height: "150%",
            }}
          />
        </Box> */}
        <img src="/general/large_icon.svg" alt="bolt_icon" style={{ order: isXS ? 1 : 3 }} />
        <Box order={{ xs: 2, md: 1 }}>
          <Typography
            component={"h3"}
            sx={{
              typography: { xs: "h4", md: "h3" },
              background: theme.palette.accentGradient,
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              backgroundClip: "text",
            }}
          >
            Enhanced Workforce Productivity & Safety
          </Typography>
        </Box>
      </Box>
      <Box
        width={"100%"}
        gap={4}
        mt={8}
        display={"flex"}
        flexDirection={{ xs: "column", md: "row" }}
        alignItems={{ xs: "start", md: "center" }}
        justifyContent={"space-between"}
      >
        {data.map((item, i) => {
          const { title, icon } = item;
          const Icon = icon;
          return (
            <Box key={i} display={"flex"} alignItems={"center"}>
              <Box
                sx={{
                  mr: 2,
                  background:
                    "linear-gradient(140deg, rgba(215, 213, 213, 0.08) 14.9%, rgba(255, 255, 255, 0.08) 38.71%, rgba(33, 36, 38, 0.08) 71.41%)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: 48,
                  height: 48,
                  borderRadius: "50%",
                }}
              >
                <GradientIconLight Icon={Icon} sx={{ fontSize: 33 }} />
              </Box>
              <Typography variant="h6">{title}</Typography>
            </Box>
          );
        })}
      </Box>
    </Container>
  );
}

export default Benefits;
