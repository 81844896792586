import React from "react";
import Box from "@mui/material/Box";
import Testimonial from "../../components/Home/Testimonial";
import Hero from "../../components/Home/Hero";
import { useFormProvider } from "../../providers/FormProvider";
import Benefits from "../../components/Home/Benefits";
import Usecases from "../../components/Home/Usecases";
import Metrics from "../../components/Home/Metrics";
import OurOfferings from "../../components/Home/OurOfferings";
import CTA from "../../components/Home/CTA";
import Security from "../../components/Home/Security";
import {
  AlarmOnRounded,
  AssignmentRounded,
  MenuBookRounded,
  QuestionAnswerRounded,
  SearchRounded,
  TroubleshootRounded,
  QuickreplyRounded as QuickreplyRoundedIcon,
  Schema as SchemaRoundedIcon,
  RateReview as RateReviewRoundedIcon,
  Assessment as AssessmentRoundedIcon,
  IntegrationInstructions as IntegrationInstructionsIcon,
  ManageSearch as ManageSearchIcon,
  BugReport as BugReportIcon,
  CrisisAlert as CrisisAlertIcon,
  WorkspacePremium as WorkspacePremiumIcon,
} from "@mui/icons-material";

const features = [
  {
    title: "Search & Make Sense of Information Instantly",
    subtitle:
      "Search all your internal data as well as our extensive library of manufacturing resources, including SOPs, manuals, P&IDs, and industry standards.",
    image: "/our_offerings/container_1.svg",
    chips: [
      {
        icon: MenuBookRounded,
        label: "Understand",
        position: { top: 0, right: 64, transform: "translateY(-50%)" },
      },
      {
        icon: SearchRounded,
        label: "Search",
        position: { bottom: 32, left: 0, transform: "translateX(-50%)" },
      },
    ],
  },
  {
    title: "Unlock Actionable Insights from Your Data",
    subtitle:
      "Aggregate Insights from Multiple Data Sources for Accurate Answers to Your Most Important Questions, Backed by Auditable Citations",
    image: "/our_offerings/container_2.svg",
    chips: [
      {
        icon: TroubleshootRounded,
        label: "Analyse",
        position: { top: 48, right: 0, transform: "translateX(50%)" },
      },
      {
        icon: QuestionAnswerRounded,
        label: "Ask",
        position: { bottom: 72, left: 0, transform: "translateX(-50%)" },
      },
    ],
  },
  {
    title: "Craft Reports and Documents Effortlessly",
    subtitle: "Compile well-researched reports that engineers would usually take weeks to complete",
    image: "/our_offerings/container_3.svg",
    chips: [
      {
        icon: AssignmentRounded,
        label: "Reports",
        position: { top: 56, right: 0, transform: "translateX(50%)" },
      },
      {
        icon: AlarmOnRounded,
        label: "Quick Turnaround",
        position: { bottom: 32, left: 0, transform: "translateX(-50%)" },
      },
    ],
  },
];

const usecases = [
  {
    key: 0,
    title: "Fast Procedures Retrieval",
    description:
      "Quickly retrieve Standard Operating Procedures, Standard Operating Conditions, Maintenance Procedures & HSEF procedures via natural language.",
    image: QuickreplyRoundedIcon,
  },
  {
    key: 1,
    title: "Smart P&IDs",
    description:
      "Highlight SOP activities on P&IDs, using tag numbers for defined valves and indicating upstream valves when specific numbers are unavailable.",
    image: SchemaRoundedIcon,
  },
  {
    key: 2,
    title: "Accelerated Project Document Review",
    description:
      "Streamline the review process for project documents from EPC contractors. This enables faster, more thorough reviews, enhancing overall project efficiency.",
    image: RateReviewRoundedIcon,
  },
  {
    key: 3,
    title: "Enhanced Process Hazard Analysis (PHA)",
    description:
      "Streamline PHA by quickly accessing and analyzing years of incident data, improving safety management and compliance in process plants.",
    image: AssessmentRoundedIcon,
  },
  {
    key: 4,
    title: "Project Learning Integration",
    description:
      "Enhance new plant designs by leveraging past project experiences. Our system quickly retrieves indexed learnings from previous projects, facilitating efficient implementation of improvements during document reviews.",
    image: IntegrationInstructionsIcon,
  },
  {
    key: 5,
    title: "Troubleshooting Knowledge Base",
    description:
      "Create a comprehensive knowledge base by indexing past problems and solutions. This enables quick access to relevant information, saving time and reducing dependency on individual expertise while making valuable knowledge available company-wide.",
    image: ManageSearchIcon,
  },
  {
    key: 6,
    title: "Efficient RCA Preparation",
    description:
      "Streamline Root Cause Analysis (RCA) preparation using indexed multidisciplinary data. This reduces preparation time and enables faster, comprehensive analysis of complex issues across disciplines.",
    image: BugReportIcon,
  },
  {
    key: 7,
    title: "Improved HIRA Risk Analysis",
    description:
      "Improve Hazard Identification and Risk Assessment (HIRA) for any job by leveraging a comprehensive database of past incidents. This approach replaces reliance on memory with data-driven insights, ensuring more thorough and accurate risk analysis.",
    image: CrisisAlertIcon,
  },
  {
    key: 8,
    title: "PTW Completion, Review and Assistance",
    description:
      "Faster and error proof permit to workprocess by AI assisted completion, automated review, and guidance. This ensures accuracy, reduces errors, and accelerates the overall permitting workflow.",
    image: WorkspacePremiumIcon,
  },
];

export default function Home() {
  const { handleOpenForm } = useFormProvider();

  return (
    <Box sx={{ overflowX: "hidden" }}>
      <Hero title1={"Gen AI Powered,"} title2={"Future Ready Manufacturing"} />
      <Benefits
        title={
          "Raven is your Gen AI Partner for transforming Operations, Maintenance & Engineering"
        }
        subtitle={`In today's complex and rapidly changing business environment, companies need AI to stay competitive and efficient. Raven helps your entire organization work better by:\n1. Making operations more efficient\n2. Saving valuable time\n3. Helping teams make decisions faster\n4. Supporting your existing staff\n5. Solving your specific business challenges\n\nWe customize our solution to address your company's unique needs and drive measurable improvements to your bottom line.`}
      />
      <OurOfferings features={features} />
      <Usecases usecases={usecases} />
      <Metrics />
      <Testimonial />
      <Security />
      <CTA title={"Leap into the future with Raven AI"} onClick={handleOpenForm} />
    </Box>
  );
}
