import React, { useRef, useCallback } from "react";
import Container from "../Container";
import { Typography, Box, Card, IconButton } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay, EffectFade, Navigation, Pagination } from "swiper/modules";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  gradientText: {
    textAlign: "center",
    background: "linear-gradient(292deg, #732668 0.1%, #402673 50.05%, #2F2673 100%)",
    backgroundClip: "text",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
  },
}));

const testimonials = [
  {
    key: 0,
    name: "Prasad M",
    role: "Plant Engineer",
    testimonial:
      "Raven is a game changer for our operations, particularly in troubleshooting plant issues. It has enabled junior engineers to save over 10 hours per week  on documentation, allowing us to focus on strategic projects that often get overshadowed by the daily routine of data review, reports, and repetitive tasks.",
  },
];

function Testimonial() {
  const { classes } = useStyles();
  const sliderRef = useRef(null);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  return (
    <Box
      // sx={{ background: "linear-gradient(90deg, #F9F9FF 50%, #FFF 100%)" }}
      px={{ md: 20, xs: 2 }}
      pt={{ md: 15, xs: 6 }}
      // pb={{ md: 4, xs: 6 }}
    >
      <Box maxWidth={1120} width={"100%"} margin={"0 auto"}>
        {/* <Typography
          className={classes.gradientText}
          sx={{ typography: { xs: "h3", md: "h2" } }}>
          See why customers choose Raven AI
        </Typography>
        <Box mb={{ xs: 4, md: 7 }} /> */}
        <Swiper
          ref={sliderRef}
          slidesPerView={1}
          spaceBetween={30}
          effect={"fade"}
          loop={true}
          // autoplay={{
          //   delay: 2000,
          //   disableOnInteraction: false,
          // }}
          // navigation={true}
          // pagination={{
          //   clickable: true,
          // }}
          modules={[Autoplay, EffectFade, Navigation, Pagination]}
        >
          {testimonials.map((t) => {
            const { key, name, role, testimonial } = t;
            return (
              <SwiperSlide key={key}>
                <Box
                  component={Card}
                  px={{ xs: 3, md: 10 }}
                  pb={{ xs: 3, md: 4 }}
                  borderRadius={8}
                  sx={{
                    position: "relative",
                    overflow: "hidden",
                    background: "linear-gradient(104deg, #FFF 1.1%, #FCF7FF 98.41%)",
                    border: "1px solid rgba(169, 61, 253, 0.16)",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box display="flex" justifyContent="center" alignItems="center">
                    <FormatQuoteIcon
                      sx={{
                        transform: "rotate(180deg)",
                        fontSize: 200,
                        opacity: 0.2,
                        color: "rgba(169, 61, 253, 0.5)",
                        zIndex: 0,
                      }}
                    />
                  </Box>
                  <Typography
                    textAlign={"center"}
                    sx={{ typography: { xs: "h4", md: "h4" }, position: "relative", zIndex: 1 }}
                  >
                    {testimonial}
                  </Typography>
                  <Box mb={6} />
                  <Typography textAlign={"center"} sx={{ typography: { xs: "h6", md: "h6" } }}>
                    {name}
                  </Typography>
                  <Typography
                    textAlign={"center"}
                    sx={{ typography: { xs: "subtitle2", md: "subtitle2" } }}
                    fontWeight={400}
                  >
                    {role}
                  </Typography>
                  <Box mb={4} />
                </Box>
              </SwiperSlide>
            );
          })}
          {/* <Box
            position={"absolute"}
            width={"100%"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            sx={{ left: 0, bottom: { xs: 25, md: 45 }, zIndex: 1 }}
          >
            <IconButton onClick={handlePrev}>
              <ArrowBack sx={{ fontSize: 32 }} />
            </IconButton>
            <IconButton onClick={handleNext}>
              <ArrowForward sx={{ fontSize: 32 }} />
            </IconButton>
          </Box> */}
        </Swiper>
      </Box>
    </Box>
  );
}

export default Testimonial;
