import React from "react";
import Box from "@mui/material/Box";
import Testimonial from "../../components/Home/Testimonial";
import Hero from "../../components/Home/Hero";
import { useFormProvider } from "../../providers/FormProvider";
import Benefits from "../../components/Home/Benefits";
import Usecases from "../../components/Home/Usecases";
import Metrics from "../../components/Home/Metrics";
import OurOfferings from "../../components/Home/OurOfferings";
import Security from "../../components/Home/Security";
import CTA from "../../components/Home/CTA";
import {
  AlarmOnRounded,
  AssignmentRounded,
  MenuBookRounded,
  QuestionAnswerRounded,
  SearchRounded,
  TroubleshootRounded,
  WorkHistoryRounded as WorkHistoryRoundedIcon,
  QuickreplyRounded as QuickreplyRoundedIcon,
  Schema as SchemaRoundedIcon,
  RateReview as RateReviewRoundedIcon,
  Assessment as AssessmentRoundedIcon,
  IntegrationInstructions as IntegrationInstructionsIcon,
  ManageSearch as ManageSearchIcon,
  BugReport as BugReportIcon,
  CrisisAlert as CrisisAlertIcon,
  WorkspacePremium as WorkspacePremiumIcon,
} from "@mui/icons-material";

const FEATURE_STRINGS = {
  search: {
    title: "Search & Make Sense of Information Instantly",
    subtitle:
      "Search all your internal data as well as our extensive library of manufacturing resources, including SOPs, manuals, P&IDs, and industry standards.",
  },
  insights: {
    title: "Unlock Actionable Insights from Your Data",
    subtitle:
      "Aggregate Insights from Multiple Data Sources for Accurate Answers to Your Most Important Questions, Backed by Auditable Citations",
  },
  reports: {
    title: "Craft Reports and Documents Effortlessly",
    subtitle: "Compile well-researched reports that engineers would usually take weeks to complete",
  },
};

const features = [
  {
    title: FEATURE_STRINGS.search.title,
    subtitle: FEATURE_STRINGS.search.subtitle,
    image: "/our_offerings/oilandgas/Container-1.png",
    chips: [
      {
        icon: MenuBookRounded,
        label: "Understand",
        position: { top: 0, right: 64, transform: "translateY(-50%)" },
      },
      {
        icon: SearchRounded,
        label: "Search",
        position: { bottom: 32, left: 0, transform: "translateX(-50%)" },
      },
    ],
  },
  {
    title: FEATURE_STRINGS.insights.title,
    subtitle: FEATURE_STRINGS.insights.subtitle,
    image: "/our_offerings/oilandgas/Container-2.png",
    chips: [
      {
        icon: TroubleshootRounded,
        label: "Analyse",
        position: { top: 48, right: 0, transform: "translateX(50%)" },
      },
      {
        icon: QuestionAnswerRounded,
        label: "Ask",
        position: { bottom: 72, left: 0, transform: "translateX(-50%)" },
      },
    ],
  },
  {
    title: FEATURE_STRINGS.reports.title,
    subtitle: FEATURE_STRINGS.reports.subtitle,
    image: "/our_offerings/oilandgas/Container-3.png",
    chips: [
      {
        icon: AssignmentRounded,
        label: "Reports",
        position: { top: 56, right: 0, transform: "translateX(50%)" },
      },
      {
        icon: AlarmOnRounded,
        label: "Quick Turnaround",
        position: { bottom: 32, left: 0, transform: "translateX(-50%)" },
      },
    ],
  },
];

const USECASE_STRINGS = {
  procedures: {
    title: "Instant Procedure Access",
    description:
      "Access SOPs, emergency procedures, and safety protocols instantly through natural language queries. Find exact steps, safety requirements, and relevant documentation in seconds.",
  },
  pids: {
    title: "Smart P&IDs",
    description:
      "Quickly locate equipment, trace process flows, and identify isolation points across multiple P&IDs. Get instant access to related procedures and equipment documentation.",
  },
  pha: {
    title: "Enhanced Process Hazard Analysis",
    description:
      "Accelerate PHA preparation by instantly accessing historical incidents, near-misses, and safety recommendations. Ensure comprehensive hazard evaluation with automated data compilation.",
  },
  rca: {
    title: "Accelerated RCA Preparation",
    description:
      "Cut RCA preparation time by automatically compiling relevant maintenance history, operational data, and previous incidents. Transform days of manual searching into hours of focused analysis.",
  },
  maintenance: {
    title: "Informed Maintenance Planning",
    description:
      "Access complete equipment history, maintenance records, and performance data in one place. Make better planning decisions with instant insights from historical maintenance patterns and documentation.",
  },
  permitToWork: {
    title: "Streamlined Permit Processing",
    description:
      "Accelerate permit preparation by automatically identifying required safety measures, isolation points, and relevant procedures based on work type and location.",
  },
};

const usecases = [
  {
    key: 0,
    title: USECASE_STRINGS.procedures.title,
    description: USECASE_STRINGS.procedures.description,
    image: QuickreplyRoundedIcon,
  },
  {
    key: 1,
    title: USECASE_STRINGS.pids.title,
    description: USECASE_STRINGS.pids.description,
    image: SchemaRoundedIcon,
  },
  {
    key: 3,
    title: USECASE_STRINGS.pha.title,
    description: USECASE_STRINGS.pha.description,
    image: AssessmentRoundedIcon,
  },
  {
    key: 6,
    title: USECASE_STRINGS.rca.title,
    description: USECASE_STRINGS.rca.description,
    image: BugReportIcon,
  },
  {
    key: 7,
    title: USECASE_STRINGS.maintenance.title,
    description: USECASE_STRINGS.maintenance.description,
    image: CrisisAlertIcon,
  },
  {
    key: 8,
    title: USECASE_STRINGS.permitToWork.title,
    description: USECASE_STRINGS.permitToWork.description,
    image: WorkHistoryRoundedIcon,
  },
];

const CONTENT_STRINGS = {
  hero: {
    title1: "Expert AI Assistant for",
    title2: "Oil & Gas",
  },
  benefits: {
    title: "Stop Searching, Start Solving",
    subtitle:
      "Your operators and engineers waste hours searching through scattered documents and systems just to find basic information they need. We built Raven to fix that. " +
      "Raven solves three critical challenges in oil & gas operations:\n" +
      "1. <b>Time Lost on Routine Questions</b>: Your teams spend hours tracking down basic information like valve isolation procedures and equipment startup sequences - tasks that should take seconds.\n" +
      "2. <b>Knowledge Retention</b>: As experienced operators retire, decades of valuable expertise leaves with them. Raven helps preserve and transfer this critical knowledge to new hires, providing reliable support during all shifts and emergency situations.\n" +
      "3. <b>Safety Response Time</b>: In critical scenarios, immediate access to correct procedures and safety protocols is essential. Raven eliminates dangerous delays in accessing this vital information.",
  },
  cta: {
    title: "Leap into the future with Raven AI",
  },
};

export default function OilAndGas() {
  const { handleOpenForm } = useFormProvider();

  return (
    <Box sx={{ overflowX: "hidden" }}>
      <Hero
        title1={CONTENT_STRINGS.hero.title1}
        title2={CONTENT_STRINGS.hero.title2}
        bg={"/general/oilandgas.png"}
      />
      <Benefits
        title={CONTENT_STRINGS.benefits.title}
        subtitle={CONTENT_STRINGS.benefits.subtitle}
      />
      <OurOfferings features={features} />
      <Usecases usecases={usecases} />
      <Metrics />
      <Testimonial />
      <Security />
      <CTA title={CONTENT_STRINGS.cta.title} onClick={handleOpenForm} />
    </Box>
  );
}
