import React from "react";
import Container from "../../components/Container";
import { Typography, useMediaQuery, useTheme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  gradientText: {
    background: theme.palette.accentGradient,
    backgroundClip: "text",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
  },
}));

function Hero() {
  const { classes } = useStyles();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Container
      wrapperStyle={{
        marginTop: isXs ? 72.5 : 76.5,
        background: "linear-gradient(90deg, #F9F9FF 50%, #FFF 100%)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography
        className={classes.gradientText}
        textAlign={"center"}
        pb={2}
        sx={{
          color: "white",
          typography: { xs: "h3", md: "h2" },
          wordBreak: "break-word", // Allow long words to break and wrap
        }}
      >
        Driving Business Impact with AI
      </Typography>
    </Container>
  );
}

export default Hero;
