import React, { useState, useEffect } from "react";
import { Dialog, CircularProgress, Box } from "@mui/material";

function JotFormEmbed({ handleClose }) {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://tally.so/widgets/embed.js";
    script.async = true;
    script.onload = () => {
      window.Tally.loadEmbeds();
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleIframeLoad = () => {
    setIsLoading(false);
  };

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        open={true}
        onClose={handleClose}
        sx={{
          padding: 0,
          margin: 0,
          "& .MuiDialog-paper": {
            height: "100%",
            maxHeight: "none",
          },
        }}
      >
        {isLoading && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              width: "100%",
            }}
          >
            <CircularProgress />
          </Box>
        )}
        <iframe
          src="https://tally.so/r/w4NXLr?transparentBackground=1"
          width="100%"
          height="100%"
          frameBorder="0"
          marginHeight="0"
          marginWidth="0"
          title="Book a Demo"
          onLoad={handleIframeLoad}
          style={{
            minWidth: "100%",
            maxWidth: "100%",
            border: "none",
            height: "100vh",
            position: "absolute",
            top: 0,
            left: 0,
          }}
        ></iframe>
      </Dialog>
    </>
  );
}

export default JotFormEmbed;
