import React from "react";
import Container from "../../components/Container";
import { Box, Typography } from "@mui/material";

function OurStory() {
  return (
    <Container>
      <Typography sx={{ typography: { md: "h2", xs: "h3" } }}>OUR STORY</Typography>
      <Box mb={{ md: 6, xs: 5 }} />
      <Typography sx={{ typography: { xs: "body1", md: "h6" } }} color={"textSecondary"}>
        We (Priyansh & Sriyansh) started Raven because of a simple observation, that even today,
        staff in manufacturing plants waste countless hours searching through scattered documents
        and systems just to find basic information they need to do their jobs. This leads to costly
        mistakes, lost productivity, and frustrated employees. <br />
        <br />
        As software engineers who previously built systems at Apna and OkCredit (YC S18), we knew AI
        could solve this. But we also knew that without deep industry knowledge, we'd build
        something that looks good in demos but fails in the real world. That's where our father's
        30+ years of experience running operations at companies like ONGC Petro additions (Ex
        President /MD), Haldia Petrochemicals (Ex Site Head)and PetroRabigh comes in - helping us
        understand the real challenges faced by operators, engineers, and managers in these complex
        facilities. <br />
        <br />
        We're a small but focused team based in San Francisco and Bangalore, combining our software
        expertise with real industry experience. Our goal isn't to build yet another enterprise
        software platform - it's to create something that actually makes life easier for the people
        working in these plants. Whether it's a night shift operator trying to find the right
        emergency procedure, or a maintenance engineer planning tomorrow's repair work, we want
        Raven to be their trusted companion, giving them instant access to the information they
        need.
      </Typography>
    </Container>
  );
}

export default OurStory;
