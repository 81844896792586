import React from "react";
import Container from "../Container";
import { Typography, Box, Button, useTheme, useMediaQuery, Grid } from "@mui/material";
import GradientBorderButtonWrapper from "../GradientBorderButtonWrapper";
import { makeStyles } from "tss-react/mui";
import { usePostHog } from "posthog-js/react";
import { CLICK_SCHEDULE_DEMO_BTN } from "../posthogEvents";
import { useFormProvider } from "../../providers/FormProvider";

const useStyles = makeStyles()((theme) => ({
  gradientText: {
    background: "linear-gradient(306deg, #BF40AE 41.62%, #6B40BF 70.81%, #4E40BF 100%)",
    backgroundClip: "text",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
  },
}));

function Hero({ title1, title2, bg = "/general/hero_bg.mp4" }) {
  const { classes } = useStyles();
  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.down("sm"));
  const posthog = usePostHog();
  const { handleOpenForm } = useFormProvider();
  const isVideo = bg.endsWith(".mp4");

  const handleCTAClick = () => {
    handleOpenForm();
    posthog.capture(CLICK_SCHEDULE_DEMO_BTN);
  };

  return (
    <Box
      px={{ md: 20, xs: 2 }}
      py={{ md: 15, xs: 6 }}
      sx={{ marginTop: { xs: "72.5px", md: "76.5px" } }}
      position={"relative"}
    >
      {isVideo ? (
        <video
          autoPlay
          loop
          muted
          playsInline
          className="video-element"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: -1,
            height: "100%",
            width: "100%",
            objectFit: "cover",
          }}
        >
          <source src={bg} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      ) : (
        <Box
          component="img"
          src={bg}
          alt="hero background"
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: -2,
            height: "100%",
            width: "100%",
            objectFit: "cover",
            opacity: 0.5, // Added opacity to make image whiter/more transparent
          }}
        />
      )}
      <Box width={"100%"} display={"flex"} flexDirection={"column"} alignItems={"center"}>
        <Typography
          textAlign={"center"}
          sx={{
            typography: { xs: "h3", md: "h1" },
          }}
          component={"h1"}
        >
          {title1} <br /> <span className={classes.gradientText}>{title2}</span>
        </Typography>
        <Box mb={{ xs: 4, md: 6 }} />
        <GradientBorderButtonWrapper
          style={{ display: { xs: "block", md: "inline-block" }, mt: 4 }}
        >
          <Button
            variant="contained"
            size="large"
            fullWidth
            onClick={handleCTAClick}
            sx={{ padding: "10px 24px", fontSize: "1rem" }}
          >
            Book a Demo
          </Button>
        </GradientBorderButtonWrapper>
        <Box mb={{ xs: 4, md: 5 }} />
        {/* <Typography fontWeight={500} color={"textSecondary"}>
          Trusted by teams at
        </Typography>
        <Box mb={1} />
        <img src="/logo/indorama_logo.png" alt="indorama" width={200} /> */}
      </Box>
    </Box>
  );
}

export default Hero;
