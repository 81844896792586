import React from "react";
import Container from "../Container";
import { Typography, Box, Grid, useTheme, useMediaQuery } from "@mui/material";
import {
  AlarmOnRounded,
  AssignmentRounded,
  ChatRounded,
  EditNoteRounded,
  MenuBookRounded,
  QuestionAnswerRounded,
  SearchRounded,
  TimelineRounded,
  TroubleshootRounded,
} from "@mui/icons-material";
import { purple, green, blue } from "@mui/material/colors";
import { GradientIconLight } from "../GradientIcon";

const offerings = [
  {
    title: "QnA AI",
    subtitle: "Tap into Organizational Knowledge, Right Here, Right Away.",
    icon: ChatRounded,
    color: purple,
  },
  {
    title: "Insight Engine",
    subtitle: "Get On-Demand Data, Analysis, Reports and Insights",
    icon: TimelineRounded,
    color: green,
  },
  {
    title: "Co-Author",
    subtitle: "Build Reports and Documentation with a Bit of Help",
    icon: EditNoteRounded,
    color: blue,
  },
];

function OurOfferings({ features }) {
  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Container>
      <Typography width={"100%"} textAlign={"center"} sx={{ typography: { md: "h2", xs: "h3" } }}>
        Our Offerings
      </Typography>
      <Box mb={{ md: 5, xs: 4 }} />
      <Box
        mb={{ xs: 6, md: 15 }}
        gap={3}
        display={"flex"}
        flexDirection={{ xs: "column", md: "row" }}
        alignItems={{ xs: "start", md: "center" }}
        justifyContent={"space-between"}
      >
        {offerings.map((offering, i) => {
          const { title, subtitle, icon, color } = offering;
          const Icon = icon;
          return (
            <Box
              key={i}
              bgcolor={color["50"]}
              borderRadius={4}
              position="relative"
              overflow="hidden"
            >
              <Box p={3} position="relative" zIndex={1}>
                <Typography variant="h5" fontWeight={600} sx={{ color: color["900"] }}>
                  {title}
                </Typography>
                <Box mb={1} />
                <Typography variant="subtitle2">{subtitle}</Typography>
              </Box>
              <Icon
                sx={{
                  fontSize: 100,
                  color: color["100"],
                  position: "absolute",
                  right: -10,
                  bottom: -10,
                  opacity: 0.3,
                  transform: "rotate(-15deg)",
                }}
              />
            </Box>
          );
        })}
      </Box>
      <Box>
        {features.map((item, i) => {
          const { title, subtitle, image, chips } = item;
          return (
            <>
              <Box
                key={i}
                px={{ xs: 2, md: 4 }}
                pt={{ xs: 2, md: 4 }}
                sx={{
                  borderRadius: 4,
                  border: `1px solid ${theme.palette.divider}`,
                }}
              >
                <Typography
                  sx={{
                    typography: { xs: "h5", md: "h4" },
                  }}
                  component={"h5"}
                >
                  {title}
                </Typography>
                <Box mb={1} />
                <Typography fontWeight={500} color={"textSecondary"}>
                  {subtitle}
                </Typography>
                <Box mt={10} display={"flex"} justifyContent={"center"}>
                  <Box position={"relative"}>
                    <img src={image} alt={title} style={{ maxWidth: 550, width: "100%" }} />
                    {chips.map((chip) => {
                      const { label, icon, position } = chip;
                      const Icon = icon;
                      return (
                        <Box
                          borderRadius={4}
                          display={"flex"}
                          alignItems={"center"}
                          sx={{
                            padding: "12px 16px",
                            boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.12)",
                            background: "linear-gradient(0deg, #FFF 0%, #FFF 100%), #E1E1E1",
                            border: "1px solid rgba(0, 0, 0, 0.16)",
                            position: "absolute",
                            ...position,
                          }}
                        >
                          <GradientIconLight Icon={Icon} sx={{ fontSize: 30 }} />
                          <Box mr={2} />
                          <Typography variant="h6">{label}</Typography>
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
              </Box>
              {i !== features.length - 1 && <Box mb={{ xs: 6, md: 15 }} />}
            </>
          );
        })}
      </Box>
    </Container>
  );
}

export default OurOfferings;
