import React from "react";
import Container from "../../components/Container";
import {
  Box,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
  Button,
  Divider,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
// import {
//   AlternateEmail,
//   Call,
//   LocationOn,
//   Public,
// } from "../../components/GradientIcons";
import GradientIcon, { GradientIconLight } from "../../components/GradientIcon";
import GradientBorderButtonWrapper from "../../components/GradientBorderButtonWrapper";
import { useFormProvider } from "../../providers/FormProvider";
import { AlternateEmail, LocationOn, Public, Call } from "@mui/icons-material";

const useStyles = makeStyles()((theme) => ({
  container: {
    width: "100%",
    borderRadius: 32,
    border: "1px solid rgba(255, 255, 255, 0.12)",
    background: "rgba(255, 255, 255, 0.02)",
    boxShadow: "2px 2px 10px 0px rgba(169, 61, 253, 0.16)",
    padding: 32,
    margin: "0 auto",
  },
}));

const data = [
  {
    key: 0,
    label: "US Office",
    sublabel: "2261 Market Street STE 5152 San Francisco, CA 94114",
    icon: <GradientIconLight Icon={LocationOn} />,
  },
  {
    key: 1,
    label: "India Office",
    sublabel: "WeWork Embassy TechVillage, Block L, Outer Ring Rd, Bellandur, Bangalore, KA 560103",
    icon: <GradientIconLight Icon={LocationOn} />,
  },
  {
    key: 2,
    label: "priyansh@startraven.com, sriyansh@startraven.com",
    icon: <GradientIconLight Icon={AlternateEmail} />,
    onClick: () => {
      window.location = "mailto:priyansh@startraven.com";
    },
  },
  {
    key: 4,
    label: "+91 99452 68531",
    icon: <GradientIconLight Icon={Call} />,
  },
  {
    key: 5,
    label: "www.startraven.com",
    icon: <GradientIconLight Icon={Public} />,
  },
];

function LetsConnect() {
  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.down("sm"));
  const { classes } = useStyles();
  const { handleOpenForm } = useFormProvider();

  return (
    <Container>
      <Typography
        textAlign={{ xs: "left", sm: "center" }}
        sx={{
          width: "100%",
          textAlign: "center",
          typography: { md: "h2", xs: "h3" },
        }}
        component={"h2"}
      >
        Let's Connect
      </Typography>
      <Box mb={6} />
      <Box maxWidth={720} className={isXS ? null : classes.container}>
        {data.map((item, i) => {
          const { key, label, sublabel, icon, onClick } = item;
          return (
            <Box
              key={key}
              sx={{ cursor: onClick ? "pointer" : "default" }}
              onClick={onClick ? onClick : () => {}}
            >
              <Box display={"flex"} alignItems={"flex-start"}>
                <IconButton
                  sx={{
                    background:
                      "linear-gradient(140deg, rgba(255, 255, 255, 0.08) 14.9%, rgba(255, 255, 255, 0.08) 50.44%, rgba(33, 36, 38, 0.08) 71.41%)",
                    mt: sublabel ? 0.75 : 0,
                  }}
                >
                  {icon}
                </IconButton>
                <Box mr={2} />
                <Box flex={1}>
                  {sublabel ? (
                    <>
                      <Typography variant="subtitle1" fontWeight="bold">
                        {label}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {sublabel}
                      </Typography>
                    </>
                  ) : (
                    <Typography variant="body1" sx={{ mt: 1 }}>
                      {label}
                    </Typography>
                  )}
                </Box>
              </Box>
              {i !== data.length - 1 && (
                <Divider
                  sx={{
                    my: 2,
                    borderColor: "rgba(255, 255, 255, 0.08)",
                    ml: { xs: 7, md: 8.5 },
                  }}
                />
              )}
            </Box>
          );
        })}
        <Box mt={3} display={{ xs: "block", md: "flex" }} justifyContent={"center"}>
          <GradientBorderButtonWrapper style={{ display: { xs: "block", md: "inline-block" } }}>
            <Button variant="contained" size="large" fullWidth onClick={handleOpenForm}>
              Book a Demo
            </Button>
          </GradientBorderButtonWrapper>
        </Box>
      </Box>
    </Container>
  );
}

export default LetsConnect;
