import React, { useEffect } from "react";
import { Box, useTheme } from "@mui/material";
import Hero from "./Hero";
import OurStory from "./OurStory";
import Team from "./Team";
import LetsConnect from "./LetsConnect";
import { usePostHog } from "posthog-js/react";
import { VIEW_ABOUT_US_PAGE } from "../../components/posthogEvents";

function About() {
  const theme = useTheme();
  const posthog = usePostHog();

  useEffect(() => {
    posthog.capture(VIEW_ABOUT_US_PAGE);
  }, []);

  return (
    <Box
      sx={{
        overflowX: "hidden",
      }}>
      <Hero />
      <OurStory />
      <Team />
      <LetsConnect />
    </Box>
  );
}

export default About;
