import React from "react";
import Container from "../../components/Container";
import { Typography, Box, Button, useTheme } from "@mui/material";
import GradientBorderButtonWrapper from "../../components/GradientBorderButtonWrapper";

function CTA({ title, btnText = "Book a Demo", onClick = () => {} }) {
  const theme = useTheme();
  return (
    <Container
      wrapperStyle={{
        background: theme.palette.paper,
      }}
      containerStyle={{ alignItems: "center" }}
    >
      <Typography
        sx={{
          typography: { md: "h3", xs: "h4" },
          width: "100%",
        }}
        textAlign={"center"}
      >
        {title}
      </Typography>
      {/* <Box mb={3} />
      <Typography sx={{ typography: { md: "h6", xs: "initial" } }}>
        Not sure if Raven AI for you? That's okay.
        <br /> Try it out for free and see how it fits.
      </Typography> */}
      <Box mb={{ xs: 4, md: 6 }} />
      <Box width={{ xs: "100%", md: "initial" }}>
        <GradientBorderButtonWrapper>
          <Button fullWidth variant="contained" onClick={onClick} size="large">
            {btnText}
          </Button>
        </GradientBorderButtonWrapper>
      </Box>
    </Container>
  );
}

export default CTA;
