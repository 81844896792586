import React from "react";
import Container from "../../components/Container";
import { Typography, Box, IconButton, Grid } from "@mui/material";
import {
  SyncRounded,
  BuildCircleRounded,
  AutoAwesomeRounded,
  CompareArrowsRounded,
  CheckCircleRounded,
} from "@mui/icons-material";
import { GradientIconLight } from "../../components/GradientIcon";

const securityItems = [
  {
    key: 1,
    label: "End-to-end encryption and role-based access control for data protection",
    icon: <GradientIconLight Icon={CheckCircleRounded} />,
  },
  {
    key: 2,
    label: "SOC2 compliant, CCPA and GDPR ready, pursuing ISO 27001 certification",
    icon: <GradientIconLight Icon={CheckCircleRounded} />,
  },
  {
    key: 3,
    label: "Strict no-retention policy for third-party AI services",
    icon: <GradientIconLight Icon={CheckCircleRounded} />,
  },
  {
    key: 4,
    label: "Regular security audits, penetration testing, and compliance checks",
    icon: <GradientIconLight Icon={CheckCircleRounded} />,
  },
];

function Security() {
  return (
    <Container>
      <Grid container columnSpacing={2.5}>
        <Grid item md={4}>
          <Typography sx={{ typography: { md: "h3", xs: "h4" } }}>
            Enterprise Security
          </Typography>
        </Grid>
        <Grid item md={8}>
          <Box>
            {securityItems.map((securityItem, i) => {
              const { key, icon, label } = securityItem;
              return (
                <Box key={key} display={"flex"} alignItems={"center"} p={1}>
                  <IconButton
                    sx={{
                      background:
                        "linear-gradient(140deg, rgba(215, 213, 213, 0.08) 14.9%, rgba(255, 255, 255, 0.08) 38.71%, rgba(33, 36, 38, 0.08) 71.41%)",
                    }}>
                    {icon}
                  </IconButton>
                  <Box mr={2} />
                  <Typography fontWeight={500}>{label}</Typography>
                </Box>
              );
            })}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Security;
