import React from "react";
import { Box, Grid, Link, Typography, useMediaQuery, useTheme } from "@mui/material";
import Container from "../Container";
import Logo from "../Logo";
import {
  CHEMICALS_URL,
  MANUALS_AND_CODE_SEARCH_URL,
  OEM_360_ASSIST_URL,
  OIL_AND_GAS_URL,
  OPERATIONS_AND_MAINTENANCE_ASSISTANT_URL,
  VOICE_GUIDED_FIELD_ASSISTANT_URL,
  VOICE_INSPECTION_GUIDANCE_URL,
} from "../../Routes";
import { NavLink } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

export const products = [
  {
    key: "oilandgas",
    label: "Oil & Gas",
    path: OIL_AND_GAS_URL,
    icon: (
      <img
        src="/manuals_and_code_search/ai_industries_manual.png"
        alt="icon"
        width={24}
        height={24}
      />
    ),
    type: "internal",
  },
  {
    key: "chemicals",
    label: "Chemicals, Petrochemicals & Fertilizers",
    path: "/",
    icon: (
      <img
        src="/manuals_and_code_search/ai_industries_manual.png"
        alt="icon"
        width={24}
        height={24}
      />
    ),
    type: "internal",
  },
];

const useStyles = makeStyles()((theme) => ({
  footerItem: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      alignItems: "center",
    },
  },
}));

function Footer() {
  const { classes } = useStyles();
  const theme = useTheme();
  return (
    <Container>
      <Grid container rowSpacing={5} columnSpacing={5}>
        <Grid item xs={12} sm={6} lg>
          <Box className={classes.footerItem}>
            <Link component={NavLink} to={""} underline="none">
              <Logo />
            </Link>
            <Box mb={1} />
            <Typography variant="subtitle2">© Raven.dev, Inc. 2024</Typography>
            <Box mb={1} />
            <Typography variant="subtitle2">Based in San Francisco & Bengaluru</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} lg>
          <Box className={classes.footerItem}>
            <Typography variant="subtitle2">Backed by</Typography>
            <img src="/logo/yc_logo.svg" alt="yc" width={200} height={80} />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} lg>
          <Box className={classes.footerItem}>
            <Typography variant="subtitle2">Industries</Typography>
            <Box mb={1} />
            {products.map((product) => {
              const { key, label, path } = product;
              return (
                <Link
                  color={"secondary"}
                  key={key}
                  component={NavLink}
                  to={path}
                  sx={{
                    display: "block",
                    marginBottom: 1,
                    "&:hover": {
                      color: theme.palette.primary.main,
                    },
                  }}
                >
                  {label}
                </Link>
              );
            })}
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} lg>
          <Box className={classes.footerItem}>
            <Typography variant="subtitle2">Contact us at</Typography>
            <Box mb={1} />
            <Link
              color={"secondary"}
              onClick={() => (window.location = "mailto:priyansh@startraven.com")}
              sx={{
                "&:hover": {
                  color: theme.palette.primary.main,
                },
              }}
            >
              priyansh@startraven.com
            </Link>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Footer;
